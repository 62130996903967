import useSafeState from '../../utils/safe-state';
import React, {FC, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, Select} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {antdContext} from "../../utils/antdContext";
import ProductModel from "../../models/dto/product-model";
import {SelectType} from "../../interfaces/SelectType";

interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const ProductForm  = (props: Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const config = {headers: {'Content-Type': 'application/json'}};
    const [active, setActive] = useSafeState(true);
    const [ftpNeed, setFtpNeed] = useSafeState(true);
    const [autoCompleteOptions,setAutoCompleteOptions] = useSafeState<any[]>([]);

    const data = props.data || {};
    const [form] = Form.useForm();

    let isEditMode = false;

    useEffect(() => {

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === "Add new") {
            isEditMode = false;
        }

        getFormData();

    }, [])

    const onReset = () => {
        form.resetFields();
    };


    const openNotification = (status: any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {
                console.log(r)
            })
        }
        if (props.onClose) {
            props.onClose();
        }
    };


    const handleEdit = () => {

        if (axiosInstance.current != null) {
            const obj = {
                code: form.getFieldValue(["code"]),
                color: form.getFieldValue(["color"]),
                type: form.getFieldValue(["type"]),
                size: form.getFieldValue(["size"]) ? {name:form.getFieldValue(["size"])} : null,
                version: form.getFieldValue(["version"])
            };
            setLoading(true);
            axiosInstance.current.put(ProductModel.url + "/id?id=" + form.getFieldValue(["code"]), obj,
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }
    const handleAddNew = () => {

        if (axiosInstance.current != null) {
            const obj = {
                code: form.getFieldValue(["code"]),
                color: form.getFieldValue(["color"]),
                type: form.getFieldValue(["type"]),
                size: form.getFieldValue(["size"]) ? {name:form.getFieldValue(["size"])} : null
            };
            setLoading(true);
            axiosInstance.current.post(ProductModel.url, obj,
                config).then(function (response: AxiosResponse<any>) {

                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }


    const getFormData = () => {

        if(!axiosInstance.current){
            return;
        }

        axiosInstance.current.get('/resource/sizes')
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {
                    let sizeArray: Array<SelectType> = [];
                    response.data.forEach(value => {

                        const label = value.name;

                        sizeArray.push(
                            {
                                label: label,
                                value: label,
                            }
                        )
                    });
                    setAutoCompleteOptions(sizeArray);
                }
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });
    };

    return (
        <Form id={props.mode} form={form} layout="vertical"
              onFinish={() => props.mode === "Edit" ? handleEdit() : handleAddNew()}>
            <>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="code"
                            label={<Trans>Code</Trans>}
                            children={<Input disabled={props.mode === "Edit"}/>}
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please type a code</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="color"
                            label={<Trans>Color</Trans>}
                            children={<Input disabled={false}/>}
                            rules={[{
                                type: "string",
                                required: false,
                                message: <Trans>Please type a color</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="type"
                            label={<Trans>Type</Trans>}
                            children={<Input disabled={false}/>}
                            rules={[{
                                type: "string",
                                required: false,
                                message: <Trans>Please type a type</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="size"
                            label={<Trans id={'Size'}/>}
                            children={
                                <Select
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder={<Trans id={'Select a size'}></Trans>}
                                    options={autoCompleteOptions}
                                />
                            }
                        />
                    </Col>

                </Row>

            </>
            {props.mode === "Edit" ?
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                props.mode === "Add new" ?
                    <>
                        <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                            <Trans>Vissza</Trans>
                        </Button>
                        <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                                onClick={() => form.submit()}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                    :
                    <></>
            }
        </Form>
    );

}
export default ProductForm;
