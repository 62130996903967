import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const ProductModel = () : ColumnMain =>
{

    const columns : Array<Column> = new Array();
    columns.push({
        key:'code', width: 200, sorter:true,title:<Trans>Code</Trans>, fixed: "left", direction:"asc", render: text => {return (<b>{text}</b>);}
    });
    columns.push({key:'color', width: 200, sorter:true,title:<Trans>Color</Trans> });
    columns.push({key:'type', width: 200, sorter:true,title:<Trans>Type</Trans> });
    columns.push({key:'size.name', dataIndex:['size','name'], width: 110, sorter:false,title:<Trans>Size</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});

    return{
        columns:columns,
        url:"/resource/products"
    };

}

export default ProductModel();
