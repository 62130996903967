import useSafeState from '../../utils/safe-state';
import React, {FC, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, InputNumber} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {antdContext} from "../../utils/antdContext";
import SizeModel from "../../models/dto/size-model";

interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const SizeForm  = (props: Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const config = {headers: {'Content-Type': 'application/json'}};
    const [active, setActive] = useSafeState(true);
    const [ftpNeed, setFtpNeed] = useSafeState(true);

    const data = props.data || {};
    const [form] = Form.useForm();

    let isEditMode = false;

    useEffect(() => {

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === "Add new") {
            isEditMode = false;
        }
    }, [])

    const onReset = () => {
        form.resetFields();
    };


    const openNotification = (status: any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {
                console.log(r)
            })
        }
        if (props.onClose) {
            props.onClose();
        }
    };


    const handleEdit = () => {

        if (axiosInstance.current != null) {
            const obj = {
                name: form.getFieldValue(["name"]),
                widthX: form.getFieldValue(["widthX"]),
                lengthY: form.getFieldValue(["lengthY"]),
                version: data.version
            };
            setLoading(true);
            axiosInstance.current.put(SizeModel.url + "/id?id=" + form.getFieldValue(["name"]), obj,
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }
    const handleAddNew = () => {

        if (axiosInstance.current != null) {
            const obj = {
                name: form.getFieldValue(["name"]),
                widthX: form.getFieldValue(["widthX"]),
                lengthY: form.getFieldValue(["lengthY"]),
                version: form.getFieldValue(["version"]),
            };
            setLoading(true);
            axiosInstance.current.post(SizeModel.url, obj,
                config).then(function (response: AxiosResponse<any>) {

                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const onChange = () => {
        setActive(!active);
    }
    const onChangeFtpNeed = () => {
        setFtpNeed(!ftpNeed);
    }

    return (
        <Form id={props.mode} form={form} layout="vertical"
              onFinish={() => props.mode === "Edit" ? handleEdit() : handleAddNew()}>
            <>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="name"
                            label={<Trans>Name</Trans>}
                            children={<Input disabled={props.mode === "Edit"}/>}
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please type a name</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="widthX"
                            label={<Trans>Width X</Trans>}
                            children={<InputNumber disabled={false}/>}
                            rules={[{
                                type: "number",
                                required: true,
                                message: <Trans>Please type a width</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="lengthY"
                            label={<Trans>Length Y</Trans>}
                            children={<InputNumber disabled={false}/>}
                            rules={[{
                                type: "number",
                                required: true,
                                message: <Trans>Please type a length</Trans>
                            }]}
                        />
                    </Col>
                </Row>

            </>
            {props.mode === "Edit" ?
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                props.mode === "Add new" ?
                    <>
                        <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                            <Trans>Vissza</Trans>
                        </Button>
                        <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                                onClick={() => form.submit()}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                    :
                    <></>
            }
        </Form>
    );

}
export default SizeForm;
