import useSafeState from '../utils/safe-state';
import * as React from 'react'
import en from '../locales/en/messages.json';
import hu from '../locales/hu/messages.json';
import {BrowserRouter as Router, Link, Switch} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web'
import {PrivateRoute} from './utils'
import {Button, Col, Dropdown, Form, Input, Layout, Menu, message, Modal, Row, Tooltip} from 'antd';
import SubMenu from "antd/es/menu/SubMenu";

import LanguageChange from "../components/language-change/language-change";
import {Language} from "../models/language/language";

import {Trans} from "@lingui/macro";

import {i18n} from '@lingui/core'

import "../routes/App.css";
import './App.css';
import 'antd/dist/antd.css';
import "../routes/index.css";
import logo from "../images/head_logo.png"
import huFlag from "../icons/hu.png";
import enFlag from "../icons/en.png";
import {
    BarcodeOutlined,
    DatabaseOutlined, DownloadOutlined, HistoryOutlined,
    HomeOutlined,
    MenuFoldOutlined, MenuUnfoldOutlined, QrcodeOutlined, ScanOutlined
} from "@ant-design/icons";
import {
    MessageOutlined,
    CalendarOutlined,
    AlertOutlined,
    CopyrightOutlined,
    ControlOutlined,
    ReconciliationOutlined,
    LogoutOutlined,
    QuestionCircleOutlined, ExportOutlined
} from '@ant-design/icons/lib';

import {Footer} from "antd/lib/layout/layout";
import EmailLogScreen from "../screens/email-logs/email-logs";
import MessageDocumentScreen from "../screens/message-document/messages-documents";
import SupplierOrderScreen from "../screens/supplier-orders/supplier-orders";
import CustomerOrderScreen from "../screens/customer-orders/customer-orders";
import UserManagementScreen from "../screens/user-management/user-management";
import isAuthorized from "../utils/is-authorized";
import SupportIcon from "../components/icons/support-icon";
import Icon from '@ant-design/icons';
import {useAxios} from "../utils/hooks";
import {useState} from "react";
import {AxiosResponse} from "axios";
import QRCode from "qrcode";
import {antdContext} from "../utils/antdContext";
import AllRole, {Roles} from "../utils/roles";
import SizeScreen from "../screens/sizes/sizes";
import ProductScreen from "../screens/products/products";
import SlotScreen from "../screens/slots/slots";
import ScanLogScreen from "../screens/scanlogs/scanlogs";
import StockScreen from "../screens/stocks/stocks";
import MovementHistoryScreen from "../screens/movement-history/movement-history";
import DeliveryScreen from "../screens/delivery/delivery";
import CustomerScreen from "../screens/customers/customers";

const {Header, Sider, Content} = Layout;

export const AppRouter = () => {

    const SupportIco = (props: any) => <Icon component={SupportIcon} {...props} />;

    const {initialized, keycloak} = useKeycloak();
    const myStorage = window.localStorage;
    const axiosInstance = useAxios("")

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [qrCode, setQrCode] = useState<any>();

    const languageModel: Language[] = [
        {
            language: "hu",
            icon: huFlag
        },
        {
            language: "en",
            icon: enFlag
        }
    ];

    const [loggedUser, setLoggedUser] = useSafeState<any>('')
    const [collapsed, setCollapsed] = useSafeState(false)
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }


    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [form] = Form.useForm();
    form.resetFields();

    React.useEffect(() => {

        if (keycloak && keycloak.tokenParsed) {

            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            if (keycloak.tokenParsed != null) {
                setLoggedUser(obj.preferred_username);
                localStorage.setItem('username', obj.preferred_username);
            }
        }

    }, [initialized, keycloak]);

    const openWikiJs = () => {
        console.log(window.location.pathname);
        let pathname =
            ((window.location.pathname == "/" || window.location.pathname == "/landing")
                ? "/home"
                : window.location.pathname).replace( new RegExp("[0-9]+","gm"),"");

        window.open(process.env.REACT_APP_WIKI_URL + pathname, '_blank');
    }

    if (!initialized) {
        return <div>
            <Trans>Loading...</Trans>
        </div>
    }

    const getQrModal = () => {
        showModal();
    };
    const generateQrIcon = async (password : String)   =>
    {
        const encodedString = Buffer.from('{username:"'+loggedUser+'",password:"'+password+'"}').toString('base64');
        let at = await QRCode.toDataURL(encodedString);
        setQrCode(at);
    }
    const onQrFormFinish = async (values: any) => {

        const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}};

        const params = new URLSearchParams()
        params.append('username', values.user)
        params.append('password', values.pass)
        params.append('client_id', 'slate-design-frontend')
        params.append('grant_type', 'password')

        if (axiosInstance.current != null) {
            axiosInstance.current.post(
                process.env.REACT_APP_API_KEYCLOAK_URL + "/auth/realms/slate-design/protocol/openid-connect/token",params, config
            ).then(function (response: AxiosResponse<any>) {

                generateQrIcon(values.pass);

            }).catch(function (error) {
                message.error(antdContext('Hibás jelszó!'));
            })
        }
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => {
                console.log(window.location.origin);
                keycloak.redirectUri = window.location.origin;
                keycloak.logout();
                localStorage.clear();
            }}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><LogoutOutlined/></span><span><Trans>Logout</Trans></span></span>
            </Menu.Item>
            <Menu.Item onClick={() => getQrModal()}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><QrcodeOutlined /></span><span><Trans>Print Qr</Trans></span></span>
            </Menu.Item>
        </Menu>
    );

    return (

        <Router>
            <Layout style={{minHeight: "100vh"}}>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <Menu theme={"dark"} mode="inline" defaultSelectedKeys={['1']}>

                        {isAuthorized(keycloak,[AllRole()]) ?
                            <Menu.Item key="1" icon={<HomeOutlined/>}>
                                <Link to="/landing"><Trans>Kezdőoldal</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="/supplier-orders" icon={<ReconciliationOutlined/>}>
                                <Link to="/supplier-orders"><Trans>Supplier Orders</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="/customer-orders" icon={<ReconciliationOutlined/>}>
                                <Link to="/customer-orders"><Trans>Customer Orders</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="delivery" icon={<ExportOutlined />}>
                                <Link to="/delivery"><Trans>Delivery</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="stocks" icon={<ReconciliationOutlined/>}>
                                <Link to="/stocks"><Trans>Stocks</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="movement-histories" icon={<HistoryOutlined/>}>
                                <Link to="/movement-histories"><Trans>Movement history</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,[Roles.Admin]) ?
                            <SubMenu key="MasterDataSubMenu" icon={<DatabaseOutlined/>} title={<Trans>Törzsadatok</Trans>}>
                                <Menu.Item key="sizes">
                                    <Link to="/sizes"><Trans>Sizes</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="products">
                                    <Link to="/products"><Trans>Products</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="slots">
                                    <Link to="/slots"><Trans>Slots</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="customers">
                                    <Link to="/customers"><Trans>Customers</Trans></Link>
                                </Menu.Item>
                            </SubMenu>
                            : <></>
                        }

                        {isAuthorized(keycloak,AllRole()) ?
                            <Menu.Item key="scan-logs" icon={<BarcodeOutlined/>}>
                                <Link to="/scan-logs"><Trans>Scan logs</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,[Roles.Admin]) ?
                            <>
                                <Menu.Item key="users-management" icon={<ControlOutlined/>}>
                                    <Link to="/users-management"><Trans>Users management</Trans></Link>
                                </Menu.Item>
                            </> :
                            <></>
                        }
                    </Menu>
                </Sider>
                <Layout className="site-layout ">
                    <Header className="site-layout-background" style={{textAlign: "center", padding: 0}}>
                        <Row>
                            <Col span={10}>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggleCollapse,
                                    style: {float: 'left'}
                                })}
                            </Col>
                            <Col span={4}>
                                    <span style={{textAlign: "center", width: 125}}>
                                        <img src={logo} width={150}/>
                                    </span>
                            </Col>
                            <Col span={10}>
                                    <span style={{float: 'right'}}>


                                        <Tooltip placement="bottom" title={<Trans>Help</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <QuestionCircleOutlined style={{marginRight: 20, fontSize: 'x-large'}}
                                                                        onClick={() => openWikiJs()}/>
                                            </span>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={<Trans>Support</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <a style={{textDecoration: "none", color: "black"}} href={"mailto:support@logicloud.hu"}><SupportIco style={{ marginRight: 20, fontSize: 'x-large' }} /></a>
                                            </span>
                                        </Tooltip>

                                        <span
                                            className={process.env.REACT_APP_ENVIRONMENT}>{process.env.REACT_APP_ENVIRONMENT}</span>

                                        <LanguageChange
                                            handleLanguageChange={(e) => {
                                                i18n.activate(e.key + '');
                                                myStorage.setItem('language', e.key);
                                            }}
                                            activeLanguage={localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'hu'}
                                            languages={languageModel}/>

                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button style={{border: 0}}><span
                                                style={{fontSize: 'large'}}>{loggedUser} </span></Button>
                                        </Dropdown>
                                        <Modal
                                            title={<Trans>Print QR to application</Trans>}
                                            visible={isModalVisible}
                                            footer={[]}
                                            onCancel={handleCancel}>

                                                     <Form
                                                         name="basic"
                                                         labelCol={{ span: 8 }}
                                                         wrapperCol={{ span: 16 }}
                                                         initialValues={{ remember: true }}
                                                         onFinish={onQrFormFinish}

                                                     >
                                                      <Form.Item
                                                          label={<Trans>Username</Trans>}
                                                          initialValue={loggedUser}
                                                          name="user"
                                                          rules={[{ required: true,  message: 'Please input your username!' }]}
                                                      >
                                                        <Input disabled={true}/>
                                                      </Form.Item>

                                                      <Form.Item
                                                          label={<Trans>Password</Trans>}
                                                          name="pass"
                                                          rules={[{ required: true, message: <Trans>Please input your password!</Trans> }]}
                                                      >
                                                        <Input.Password />
                                                      </Form.Item>

                                                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                                        <Button type="primary" htmlType="submit">
                                                          <Trans>Get my QR</Trans>
                                                        </Button>
                                                      </Form.Item>
                                                    </Form>
                                                  <Row>
                                                    {
                                                        qrCode == null ? "" : <Col span={24} style={{justifyContent:'center',display:'flex'}}>
                                                            <img  src={qrCode}/>
                                                        </Col>
                                                    }
                                                      {
                                                          qrCode == null ? "" :
                                                              <Col span={24} style={{justifyContent:'center',display:'flex'}}>
                                                                  <a download={loggedUser} href={qrCode}>
                                                                      <Button type="primary" shape="round" icon={<DownloadOutlined />} size={'large'}>
                                                                          <span><Trans>Download</Trans></span>
                                                                      </Button>
                                                                  </a>
                                                              </Col>
                                                      }
                                                  </Row>

                                              </Modal>
                                    </span>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px 8px',
                            padding: 20
                        }}
                    >
                        <Switch>
                            <PrivateRoute roles={AllRole()} exact path="/" component={SupplierOrderScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/slots" component={SlotScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/movement-histories" component={MovementHistoryScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/scan-logs" component={ScanLogScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/stocks" component={StockScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/delivery" component={DeliveryScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/sizes" component={SizeScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/customers" component={CustomerScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/products" component={ProductScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/supplier-orders" component={SupplierOrderScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/customer-orders" component={CustomerOrderScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/message-documents" component={MessageDocumentScreen}/>
                            <PrivateRoute roles={AllRole()} exact path="/email-logs" component={EmailLogScreen}/>
                            <PrivateRoute roles={[Roles.Admin]} exact path="/users-management" component={UserManagementScreen}/>
                        </Switch>
                    </Content>
                    <Footer style={{ textAlign: "center", fontSize: 10, paddingTop: 0, paddingRight: 10, paddingLeft: 10, paddingBottom: 9}}>
                        <Row>
                            <Col span={8}>
                            </Col>
                            <Col span={8}><CopyrightOutlined /> <span> 2022 Copyright  Slate Design Kft.. | Created by <a href="https://www.logicloud.hu" target="_blank" >LogiCloud Kft.</a></span></Col>
                            <Col span={8} style={{textAlign: "right"}}>Ambarella5-SNAPSHOT</Col>
                        </Row>
                    </Footer>
                </Layout>
            </Layout>
        </Router>
    )
}
