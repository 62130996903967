import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import ScanLogModel from "../../models/dto/scan-log-model";
import useSafeState from "../../utils/safe-state";
import { Breadcrumb } from "antd";
import { Trans } from "@lingui/macro";
import Drawer from "../../components/drawer/drawer";
import ScanLogForm from "../../forms/scan-log-form/scan-log-drawer";

const ScanLogScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const text = <Trans>Scan log</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <ScanLogForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Scan logs'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={ScanLogModel}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default ScanLogScreen;
