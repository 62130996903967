import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const SlotModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'rack', width: 150, direction:"desc", sorter:true,title:<Trans>Rack</Trans>});
    columns.push({key:'row', width: 150, sorter:true,title:<Trans>Row</Trans>});
    columns.push({key:'col', width: 150, sorter:true,title:<Trans>Col</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});

    return{
        columns:columns,
        url:"/resource/slots"
    };

}
export default SlotModel();
