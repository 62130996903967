import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import React from "react";

const CustomerModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 150, direction: "desc", sorter:true,title:<Trans>customerId</Trans>});
    columns.push({key:'customerCode', width: 250, sorter:true,title:<Trans>customerCode</Trans>});
    columns.push({key:'customerName', width: 250, sorter:true,title:<Trans>customerName</Trans>});
    columns.push({key:'contactName', width: 250, sorter:true,title:<Trans>contactName</Trans>});
    columns.push({key:'taxNumber', width: 250, sorter:true,title:<Trans>taxNumber</Trans>});
    columns.push({key:'euTaxNumber', width: 250, sorter:true,title:<Trans>euTaxNumber</Trans>});
    columns.push({key:'thirdCountryTaxNumber', width: 250, sorter:true,title:<Trans>thirdCountryTaxNumber</Trans>});
    columns.push({key:'centralZip', width: 250, sorter:true,title:<Trans>centralZip</Trans>});
    columns.push({key:'centralCity', width: 250, sorter:true,title:<Trans>centralCity</Trans>});
    columns.push({key:'centralStreet', width: 250, sorter:true,title:<Trans>centralStreet</Trans>});
    columns.push({key:'centralPublicDomain', width: 250, sorter:true,title:<Trans>centralPublicDomain</Trans>});
    columns.push({key:'centralNumber', width: 250, sorter:true,title:<Trans>centralNumber</Trans>});
    columns.push({key:'centralBuilding', width: 250, sorter:true,title:<Trans>centralBuilding</Trans>});
    columns.push({key:'centralStaircase', width: 250, sorter:true,title:<Trans>centralStaircase</Trans>});
    columns.push({key:'centralFloor', width: 250, sorter:true,title:<Trans>centralFloor</Trans>});
    columns.push({key:'centralDoor', width: 250, sorter:true,title:<Trans>centralDoor</Trans>});
    columns.push({key:'centralCountry', width: 250, sorter:true,title:<Trans>centralCountry</Trans>});
    columns.push({key:'phone', width: 250, sorter:true,title:<Trans>phone</Trans>});
    columns.push({key:'fax', width: 250, sorter:true,title:<Trans>fax</Trans>});
    columns.push({key:'email', width: 250, sorter:true,title:<Trans>email</Trans>});
    columns.push({key:'receiveNews', width: 250, sorter:true,title:<Trans>receiveNews</Trans>});
    columns.push({key:'webPage', width: 250, sorter:true,title:<Trans>webPage</Trans>});
    columns.push({key:'discountPercent', width: 250, sorter:true,title:<Trans>discountPercent</Trans>});
    columns.push({key:'paymentMethodName', width: 250, sorter:true,title:<Trans>paymentMethodName</Trans>});
    columns.push({key:'obligatoryPaymentTerms', width: 250, sorter:true,title:<Trans>obligatoryPaymentTerms</Trans>});
    columns.push({key:'expiredDebit', width: 250, sorter:true,title:<Trans>expiredDebit</Trans>});
    columns.push({key:'debit', width: 250, sorter:true,title:<Trans>debit</Trans>});
    columns.push({key:'active', width: 250, sorter:true,title:<Trans>active</Trans>});
    columns.push({key:'webShopId', width: 250, sorter:true,title:<Trans>webShopId</Trans>});
    columns.push({key:'comment', width: 250, sorter:true,title:<Trans>comment</Trans>});
    columns.push({key:'creditLine', width: 250, sorter:true,title:<Trans>creditLine</Trans>});
    columns.push({key:'vatRate', width: 250, sorter:true,title:<Trans>vatRate</Trans>});
    columns.push({key:'vatCode', width: 250, sorter:true,title:<Trans>vatCode</Trans>});

    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});

    return{
        columns:columns,
        url:"/resource/customers"
    };

}
export default CustomerModel();
