import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import MovementHistoryModel from "../../models/dto/movement-history-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Popconfirm, message, Button, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {DeleteOutlined, EditTwoTone} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import Drawer from "../../components/drawer/drawer";
import MovementHistoryForm from "../../forms/movement-history-form/movement-history-form";
import {PlusOutlined} from "@ant-design/icons/lib";

const MovementHistoryScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const handleDelete = (id: any) => {
        if(mtskAxiosInstance.current != null){
            mtskAxiosInstance.current.delete("/resource/movement-history/id?id="+id)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const text = <Trans>Movement history</Trans>


    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Movement history'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={MovementHistoryModel}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
    </>
}
export default MovementHistoryScreen;
