import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import SupplierOrderModel from "../../models/dto/supplier-order-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Button, message, Popconfirm, Space, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {EditTwoTone, FileImageTwoTone, PlusOutlined, PrinterOutlined, QrcodeOutlined} from "@ant-design/icons";
import {useKeycloak} from "@react-keycloak/web";
import OrderDrawer from "../../forms/order-form/order-drawer";
import Drawer from "../../components/drawer/drawer";
import FileDownload from "js-file-download";
import {DeleteOutlined} from "@ant-design/icons/lib";
import {antdContext} from "../../utils/antdContext";

const SupplierOrderScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();

    const [trigger, setTrigger] = useSafeState(false);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const handleDownloadPdfLabel = (orderNo : number) => {

        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/supplier-orders/get-qr-by-order-number/'+orderNo,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, new Date().toISOString().split('.')[0] + '.'+'pdf');
            }).catch(function (error: any) {
                console.log(error);
            })
        }

    }

    const text = <Trans>Supplier Order</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <OrderDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const handleDelete = (id: any) => {
        if(axiosInstance.current != null){
            axiosInstance.current.delete("/resource/supplier-orders/id?orderNumber=" + id.orderNumber + "&productCode=" + id.productCode + "&lotNumber=" + id.lotNumber)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const action = (record: any) => {

        return (
            <>
                <Tooltip placement="top" title={<Trans>Upload image</Trans>}>
                <FileImageTwoTone
                    twoToneColor={"#40a9ff"}
                    className={"ActionButtons"} onClick={
                    () =>

                        drawer.open("Edit", {
                            orderNumber: record.orderId.orderNumber,
                            productCode: record.orderId.productCode,
                            documents:record.documents,
                            lotNumber : record.orderId.lotNumber,
                            version: record.version
                        })

                        }/>
                </Tooltip>
                <Tooltip placement="top" title={<Trans>Download QR</Trans>}>
                    <QrcodeOutlined
                        className={"ActionButtons"}
                        style={{color: "#40a9ff"}}
                        onClick={() => handleDownloadQRCode(
                            record.product.color ? record.product.color : '',
                            record.orderId.lotNumber ? record.orderId.lotNumber : '',
                            record.orderId.orderNumber ? record.orderId.orderNumber : '',
                            record.orderId.productCode ? record.orderId.productCode : '',
                            record.orderQuantity,
                            record.product.size ? record.product.size.name : '',
                            record.product.type ? record.product.type : ''
                        )}

                    />
                </Tooltip>
                <Tooltip placement="top" title={<Trans>Delete supplier order</Trans>}>
                    <Popconfirm title={<Trans>Are you sure to delete this order?</Trans>}
                                onConfirm={() => handleDelete(record.orderId)}
                                okText={<Trans>Yes</Trans>}
                                cancelText={<Trans>No</Trans>}>
                        <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
                    </Popconfirm>
                </Tooltip>
            </>

        );
    }

    const handleDownloadQRCode = (color: string, lot: string, orderNumber: string, productCode: string, quantity: number, size: string, type: string): void => {
        if (axiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            axiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/slots/productqr?color=' + color + '&lot=' + lot + '&orderNumber=' + orderNumber
                        + '&productCode=' + productCode + '&quantity=' + quantity + '&size=' + size + '&type=' + type,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'qr_' + color + '_' + lot + '_' + orderNumber + '_' + productCode + '_' + quantity + '_' + size + '_' + type + '_' + '.jpg');
                });
        }
    };

    const actions = {
        width: 150,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Supplier Orders'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={SupplierOrderModel}
            action={actions}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default SupplierOrderScreen;
