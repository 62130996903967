import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Button, Col, InputNumber, Modal, Row, Space, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {EditTwoTone, FileImageTwoTone, PlusOutlined, PrinterOutlined, QrcodeOutlined} from "@ant-design/icons";
import {useKeycloak} from "@react-keycloak/web";
import DeliveryNoteModel from "../../models/dto/delivery-note-model";
import {RightOutlined} from "@ant-design/icons/lib";


const DeliveryScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();

    const [trigger, setTrigger] = useSafeState(false);
    const [actQty, setActQty] = useSafeState(0);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const [isModalVisible, setIsModalVisible] = useSafeState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setActQty(0);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const action = (record: any) => {

        return (
            <Space size="middle">
                <Tooltip placement="top" title={<Trans>Print Actual Delivery Note</Trans>}>
                    <PrinterOutlined
                        className={"ActionButtons"}
                        style={{color: "#40a9ff"}}
                        onClick={() => alert('print')
                        }
                    />
                </Tooltip>
            </Space>
        );
    }


    const actions = {
        width: 35,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const qtyText = <Trans>Quantity</Trans>

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Delivery'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={DeliveryNoteModel}
            action={actions}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
    </>
}
export default DeliveryScreen;
