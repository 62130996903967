import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const MovementHistory = () : ColumnMain => {

    const columns : Array<Column> = new Array();

    columns.push({key:'createdDate', width: 150, dateFilter:true,direction:"desc", sorter:true,title:<Trans>Datetime</Trans>});

    columns.push({key:'product.code',dataIndex:["product","code"], width: 150, sorter:true,title:<Trans>Product</Trans>});
    columns.push({key:'lotNumber', width: 150, sorter:true,title:<Trans>Lot Number</Trans>});
    columns.push({key:'quantity', width: 150, sorter:true,title:<Trans>Quantity</Trans>});

    columns.push({key:'movementType',tags:[
            {text:'RECEIVING',color:'green'},
            {text:'INTERNAL_MOVING',color:'orange'},
            {text:'DELIVERY',color:'purple'},
        ],
        uniqueSelectFilterData:[
            {
                label:<Trans>NONE</Trans>,
                value:null
            },{
                label:<Trans>RECEIVING</Trans>,
                value:"RECEIVING"
            },{
                label:<Trans>INTERNAL_MOVING</Trans>,
                value:"INTERNAL_MOVING"
            },{
                label:<Trans>DELIVERY</Trans>,
                value:"DELIVERY"
            }
        ],
        width: 110, sorter:true,title:<Trans>Movement type</Trans>
    });

    columns.push({key:'fromSlot', dataIndex:["fromSlot"], width: 150, sorter:true,title:<Trans>From slot</Trans>,
        render: (text, row) =>
            ({
                children: <div>
                    {row["fromSlot"] ? row["fromSlot"]["rack"] + ':' : ''}
                    {row["fromSlot"] ? row["fromSlot"]["row"] + ':' : ''}
                    {row["fromSlot"] ? row["fromSlot"]["col"] : ''}
                </div>
            }),
    });

    columns.push({key:'toSlot', dataIndex:["toSlot"], width: 150, sorter:true,title:<Trans>To slot</Trans>,
        render: (text, row) =>
            ({
                children: <div>
                    {row["toSlot"] ? row["toSlot"]["rack"] + ':' : ''}
                    {row["toSlot"] ? row["toSlot"]["row"] + ':' : ''}
                    {row["toSlot"] ? row["toSlot"]["col"] : ''}
                </div>
            }),
    });

    return{
        columns:columns,
        url:"/resource/movement-history"
    };

}
export default MovementHistory();
