import useSafeState from '../../utils/safe-state';
import React from 'react';
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    Divider,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Table,
    Tooltip,
} from 'antd';
import { AxiosResponse } from 'axios';
import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    FilterFilled,
    FilterOutlined,
    KeyOutlined,
    LockOutlined,
    PlusOutlined,
    RetweetOutlined,
    SearchOutlined,
} from '@ant-design/icons/lib';
import UserManagementForm from '../../forms/user-management-form/user-management-form';
import PasswordReset from '../../components/password-reset/password-reset';
import { useAxios } from '../../utils/hooks';
import RoleSettingForm from '../../forms/role-setting-form/role-setting-form';
import { notif } from '../../utils/notif';
import { antdContext } from '../../utils/antdContext';

const UserManagementScreen = () => {
    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');
    const [searchedColumn, setSearchedColumn] = useSafeState<any>();
    const [searchText, setSearchText] = useSafeState<any>();
    const [page, setPage] = useSafeState(1);
    const [size, setSize] = useSafeState(10);

    const [users, setUsers] = useSafeState<any>([]);
    const [roles, setRoles] = useSafeState<any>([]);
    const [trigger, setTrigger] = useSafeState(false);

    const [filteredInfo, setFilteredInfo] = useSafeState<any>({});

    const [isAnyFilterOnTable, setIsAnyFilterOnTable] = useSafeState(false);

    const text = <Trans>User</Trans>;
    const drawer = Drawer({
        title: text,
        children: <UserManagementForm />,
        customClose: () => setTrigger(!trigger),
    });

    const pwdTitle = antdContext('Kérlek adj meg egy ideiglenes jelszót!');
    const passwordReset = PasswordReset({
        title: pwdTitle,
        children: <></>,
        customClose: () => setTrigger(!trigger),
    });

    const roleTitle = antdContext('Kérlek válassz jogosultságot!');
    const roleSetting = RoleSettingForm({
        title: roleTitle,
        children: <span />,
        customClose: () => setTrigger(!trigger),
    });

    React.useEffect(() => {
        getUsers();
        getRealmRoles();
    }, []);

    React.useEffect(() => {
        getUsers();
    }, [trigger]);

    React.useEffect(() => {
        let isAny = false;
        for (var key of Object.keys(filteredInfo)) {
            if (filteredInfo[key] != null) {
                isAny = true;
            }
        }
        setIsAnyFilterOnTable(isAny);
    }, [filteredInfo]);

    const getUsers = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/auth/admin/realms/slate-design/users')
                .then(function (response: AxiosResponse<any>) {
                    setUsers(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const getRealmRoles = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/auth/admin/realms/slate-design/roles')
                .then(function (response: AxiosResponse<any>) {
                    setRoles(response.data);
                    console.log(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const handleDelete = (id: any) => {
        if (!axiosInstance.current) {
            return;
        }

        axiosInstance.current
            .delete('/auth/admin/realms/slate-design/users/' + id)
            .then(() => {
                notif('success', 'Az adott sor sikeresen törölve lett.');
                setTrigger(false);
                setTrigger(!trigger);
            })
            .catch(function (error: any) {
                notif('error', 'Hiba a mentés közben');
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
    };

    const action = (text: string, record: any) => (
        <>
            <EditOutlined
                className={'ActionButtons'}
                style={{ color: '#40a9ff' }}
                onClick={() => {
                    drawer.open('Edit', {
                        id: record.id,
                        username: record.username,
                        firstName: record.firstName,
                        lastName: record.lastName,
                        enabled: record.enabled,
                        email: record.email,
                    });
                }}
            />
            <Tooltip placement="top" title={<Trans>Reset password</Trans>}>
                <RetweetOutlined
                    className={'ActionButtons'}
                    style={{ color: '#73d13d' }}
                    onClick={() =>
                        passwordReset.showPromiseConfirm({
                            id: record.id,
                            username: record.username,
                        })
                    }
                />
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Set rights</Trans>}>
                <LockOutlined
                    className={'ActionButtons'}
                    style={{ color: '#ff7a45' }}
                    onClick={() => {
                        if (axiosInstance.current != null) {
                            axiosInstance.current
                                .get(
                                    '/auth/admin/realms/slate-design/users/' +
                                        record.id +
                                        '/role-mappings/realm',
                                )
                                .then(function (response: AxiosResponse<any>) {
                                    let role: any = '';
                                    for (let i = 0; i < response.data.length; i++) {

                                            role = response.data[i]['name'];

                                    }

                                    roleSetting.showPromiseConfirm({
                                        role: role,
                                        roles: roles,
                                        userId: record.id,
                                        username: record.username,
                                    });
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                });
                        }
                    }}
                />
            </Tooltip>
            <Popconfirm
                title={<Trans>Are you sure to delete this user?</Trans>}
                onConfirm={() => handleDelete(record.id)}
                okText={<Trans>Yes</Trans>}
                cancelText={<Trans>No</Trans>}
            >
                <DeleteOutlined className={'ActionButtons'} style={{ color: '#ff4d4f' }} />
            </Popconfirm>
        </>
    );

    const simpleOptions = [
        { label: <Trans>True</Trans>, value: 'true' },
        { label: <Trans>False</Trans>, value: 'false' },
    ];

    const getValue = (checkedValues: any) => {
        let isTrue: boolean = false;
        let isFalse: boolean = false;

        let searchValue = null;

        checkedValues.forEach((value: any) => {
            if (value == 'true') {
                isTrue = true;
            }
            if (value == 'false') {
                isFalse = true;
            }
        });

        if (isFalse && isTrue == false) {
            searchValue = 'false';
        } else if (isFalse == false && isTrue) {
            searchValue = 'true';
        } else if (checkedValues.length > 0 && isFalse == false && !isTrue) {
            searchValue = checkedValues;
        }
        return searchValue;
    };

    const getColumnSearchProps = (dataIndex: any, withCheckBox?: boolean) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) =>
            withCheckBox ? (
                <Checkbox.Group
                    style={{ padding: '10px' }}
                    options={simpleOptions}
                    onChange={checkedValue => {
                        setSelectedKeys(getValue(checkedValue) != null ? [getValue(checkedValue)] : []);
                        handleSearch(
                            getValue(checkedValue) != null ? [getValue(checkedValue)] : [],
                            confirm,
                            dataIndex,
                        );
                        confirm({ closeDropdown: false });
                    }}
                />
            ) : (
                <div style={{ padding: 8 }}>
                    <Row>
                        <Col>
                            <Input
                                allowClear={true}
                                value={selectedKeys[0]}
                                onChange={e => {
                                    if (e.target.value === '') {
                                        handleReset(clearFilters);
                                        confirm({ closeDropdown: false });
                                        setSearchText(selectedKeys[0]);
                                        setSearchedColumn(dataIndex);
                                    }
                                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                                }}
                                onPressEnter={(event: any) => {
                                    handleSearch(selectedKeys, confirm, dataIndex);
                                    confirm({ closeDropdown: false });
                                }}
                                style={{ marginRight: 1, width: 150 }}
                            />
                            <>
                                <Button
                                    style={{ marginRight: 1 }}
                                    type="primary"
                                    onClick={(event: any) => {
                                        handleSearch(selectedKeys, confirm, dataIndex);
                                        confirm({ closeDropdown: false });
                                    }}
                                    icon={<SearchOutlined />}
                                ></Button>
                            </>
                        </Col>
                    </Row>
                </div>
            ),
        onFilter: (value: any, record: any) => {
            if (withCheckBox == true) {
                return record[dataIndex].toString() == value;
            }
            return getProperty(record, dataIndex)
                ? getProperty(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
                : '';
        },
    });

    const getProperty = <T, K extends keyof T>(obj: T, key: K) => {
        if (obj != undefined) {
            return obj[key];
        }
        return null;
    };

    const columns = [
        {
            title: <Trans>Username</Trans>,
            dataIndex: 'username',
            key: 'username',
            filteredValue: filteredInfo.username || null,
            filterIcon: (filtered: any) => {
                return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: 5 }} />;
            },
            ...getColumnSearchProps('username'),
        },
        {
            title: <Trans>Last Name</Trans>,
            dataIndex: 'lastName',
            key: 'lastName',
            filteredValue: filteredInfo.lastName || null,
            filterIcon: (filtered: any) => {
                return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: 5 }} />;
            },
            ...getColumnSearchProps('lastName'),
        },
        {
            title: <Trans>First Name</Trans>,
            dataIndex: 'firstName',
            key: 'firstName',
            filteredValue: filteredInfo.firstName || null,
            filterIcon: (filtered: any) => {
                return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: 5 }} />;
            },
            ...getColumnSearchProps('firstName'),
        },
        {
            title: <Trans>Email</Trans>,
            dataIndex: 'email',
            key: 'email',
            filteredValue: filteredInfo.email || null,
            filterIcon: (filtered: any) => {
                return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: 5 }} />;
            },
            ...getColumnSearchProps('email'),
        },
        {
            title: <Trans>Enabled</Trans>,
            dataIndex: 'enabled',
            key: 'enabled',
            filteredValue: filteredInfo.enabled || null,
            filterIcon: (filtered: any) => {
                return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: 5 }} />;
            },
            render: (text: string, record: any) => {
                return (
                    <>
                        {record.enabled == true ? (
                            <CheckCircleOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            ...getColumnSearchProps('enabled', true),
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: 160,
            render: (text: string, record: any) => action(text, record),
        },
    ];

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const clearFilters = () => {
        setSearchedColumn(null);
        setSearchText('');
        setFilteredInfo({});
    };

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setFilteredInfo(filters);
    };

    return (
        <>

            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Felhasználó kezelés</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left', marginRight: 10 }}>
                <PlusOutlined />
                <span>
                    <Trans id={'Új hozzáadása'}></Trans>
                </span>
            </Button>
            <Tooltip placement="topLeft" title={<Trans>Clear all filters</Trans>}>
                <Button onClick={() => clearFilters()}>
                    {isAnyFilterOnTable ? (
                        <FilterFilled style={{ color: '#1890ff' }} />
                    ) : (
                        <FilterOutlined style={{ color: '#1890ff' }} />
                    )}
                </Button>
            </Tooltip>
            <div style={{ marginBottom: 10 }}></div>
            {drawer.component}
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        style={{ height: '100%' }}
                        dataSource={users}
                        columns={columns}
                        bordered
                        onChange={handleChange}
                        scroll={{ x: 1300, y: '65vh' }}
                        pagination={{
                            total: users.size,
                            pageSize: 10,
                            onChange: (page: any) => setPage(page),
                            showSizeChanger: false,
                            pageSizeOptions: ['5', '10', '15', '20', '50', '100'],
                            current: page,
                            onShowSizeChange: (current, pageSize) => setSize(pageSize),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};
export default UserManagementScreen;
