import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import React from "react";

const DeliveryNoteModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'deliveryNoteNumber',dataIndex:["deliveryNoteNumber"], width: 110, direction: "desc", sorter:true,title:<Trans>Delivery Note Number</Trans>});
    columns.push({key:'status',tags:[
            {text:'CREATED',color:'orange'},
            {text:'SCANNING_STARTED',color:'green'},
            {text:'SCANNED',color:'blue'},
            {text:'CLOSED',color:'red'},
        ],
        uniqueSelectFilterData:[
            {
                label:<Trans>NONE</Trans>,
                value:null
            },{
                label:<Trans>CREATED</Trans>,
                value:"CREATED"
            },{
                label:<Trans>SCANNING_STARTED</Trans>,
                value:"SCANNING_STARTED"
            },{
                label:<Trans>SCANNED</Trans>,
                value:"SCANNED"
            },{
                label:<Trans>CLOSED</Trans>,
                value:"CLOSED"
            }
        ],
        width: 200, sorter:true,title:<Trans>Status</Trans>
    });

    columns.push({key:'product',dataIndex:["product", "code"], width: 150, sorter:true,title:<Trans>Product</Trans>});
    columns.push({key:'lotNumber', width: 150, sorter:true,title:<Trans>Lot Number</Trans>});
    columns.push({key:'quantity', width: 150, sorter:true,title:<Trans>Quantity</Trans>});
    columns.push({key:'slot', dataIndex:["slot"], width: 150, sorter:true,title:<Trans>Slot</Trans>,
        render: (text, row) =>
            ({
                children: <div>
                    {row["slot"]["rack"]}:
                    {row["slot"]["row"]}:
                    {row["slot"]["col"]}
                </div>
            }),
    });
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});

    return{
        columns:columns,
        url:"/resource/delivery-notes"
    };

}

export default DeliveryNoteModel();
