import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const ScanLogModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true, direction:"desc", title:<Trans>Scanned Date</Trans>});
    columns.push({key:'code', width: 150, sorter:true,title:<Trans>Code</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Scanned By</Trans>});


    return{
        columns:columns,
        url:"/resource/scan-log"
    };

}
export default ScanLogModel();
