import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const StockModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();

    columns.push({key:'product.code',dataIndex:["product","code"], width: 150, direction: "asc", sorter:true,title:<Trans>Product</Trans>});
    columns.push({key:'lotNumber', width: 150, sorter:true,title:<Trans>Lot Number</Trans>});

    const qtyColumns: Array<Column> = new Array();
    qtyColumns.push({key:'quantity', width: 110, sorter:true, title:<Trans>Stock</Trans>,

            render: (text, row) => ({ props: { style: { background:
                             row["quantity"] !== row["freeQuantity"] ? '#fffbe6' : ''
            } }, children: <div>{text}</div> })
    });

    qtyColumns.push({key:'freeQuantity', width: 110, sorter:true, title:<Trans>Free</Trans>,

        render: (text, row) => ({ props: { style: { background:
                        row["quantity"] !== row["freeQuantity"] ? '#fffbe6' : ''
                } }, children: <div>{text}</div> })
    });

    qtyColumns.push({key:'openedQuantity', width: 150, sorter:true,title:<Trans>Used</Trans>,

        render: (text, row) => ({ props: { style: { background:
                        row["quantity"] !== row["freeQuantity"] ? '#fffbe6' : ''
                } }, children: <div>{text}</div> })
    });

    columns.push({
        key: 'qty',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Quantity</Trans>,
        children: qtyColumns
    });

    columns.push({key:'slot', dataIndex:["slot"], width: 150, sorter:true,title:<Trans>Slot</Trans>,
        render: (text, row) =>
            ({
                children: <div>
                    {row["slot"]["rack"]}:
                    {row["slot"]["row"]}:
                    {row["slot"]["col"]}
                </div>
            }),
    });
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/stock-view"
    };
}
export default StockModel();
