import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import SlotModel from "../../models/dto/slot-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Popconfirm, message, Button, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {DeleteOutlined, EditTwoTone, QrcodeOutlined} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import Drawer from "../../components/drawer/drawer";
import SlotForm from "../../forms/slot-form/slot-drawer";
import {PlusOutlined} from "@ant-design/icons/lib";


const SlotScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const handleDelete = (id: any) => {
        if(mtskAxiosInstance.current != null){
            mtskAxiosInstance.current.delete("/resource/slots/id?id="+id)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const action = (text: string, record: any) => {

        return  <>
            <Tooltip placement="top" title={<Trans>Delete slot</Trans>}>
                <Popconfirm title={<Trans>Are you sure to delete this slot?</Trans>}
                            onConfirm={() => handleDelete(record.id)}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}>
                    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
                </Popconfirm>
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Edit slot</Trans>}>
                <EditTwoTone
                    twoToneColor={"#40a9ff"}
                    className={"ActionButtons"} onClick={
                    () => drawer.open("Edit", {
                        id: record.id,
                        rack: record.rack,
                        col: record.col,
                        row : record.row,
                        version : record.version
                    })}/>
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Download QR</Trans>}>
                <QrcodeOutlined
                    className={"ActionButtons"}
                    style={{color: "#40a9ff"}}
                    onClick={() => handleDownloadQRCode(record.col, record.rack, record.row)}
                />
            </Tooltip>
        </>
    }

    const handleDownloadQRCode = (column: number, rack: string, row: number): void => {
        if (mtskAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            mtskAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/slots/slotqr?rack=' + rack + "&row=" + row + '&column=' + column,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'qr_' + rack + '-' + row + '-' + column + '.jpg');
                });
        }
    };

    const text = <Trans>Size</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <SlotForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const actions = {
        width: 120,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Slots'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={SlotModel}
            tableHeader={
                <Button type={"primary"} onClick={ () => drawer.open("Add new")} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new</Trans></span></Button>
            }
            action={actions}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default SlotScreen;
