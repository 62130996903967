import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import StockModel from "../../models/dto/stock-model";
import useSafeState from "../../utils/safe-state";
import {
    Breadcrumb,
    Popconfirm,
    message,
    Button,
    Tooltip,
    Popover,
    Space,
    Modal,
    InputNumber,
    Col,
    Table,
    Tag, Row, Input
} from "antd";
import {Trans} from "@lingui/macro";
import {DeleteOutlined, EditTwoTone} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import Drawer from "../../components/drawer/drawer";
import StockForm from "../../forms/stock-form/stock-drawer";
import {PlusOutlined, RightOutlined, SnippetsOutlined} from "@ant-design/icons/lib";
import {Column} from "#/models/column";
import {AxiosResponse} from "axios";

const StockScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);
    const [visible, setVisible] = useSafeState(false);
    const [actQty, setActQty] = useSafeState(0);
    const [actProduct, setActProduct] = useSafeState<any>();
    const [isModalVisible, setIsModalVisible] = useSafeState(false);
    const [deliveryNoteData, setDeliveryNoteData] = useSafeState<Array<any>>([]);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");
    const config = {headers: {'Content-Type': 'application/json'}};

    const handleDelete = (id: any) => {
        if(axiosInstance.current != null){
            axiosInstance.current.delete("/resource/stocks/id?id="+id)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const showModal = (record: any) => {
        console.log(record);
        setIsModalVisible(true);
        setActQty(0);
        setActProduct(
            {
                product: record.product,
                lotNumber: record.lotNumber,
                quantity: 0,
                slot: record.slot
            }
        );
    };

    const handleOk = () => {
        setIsModalVisible(false);
        actProduct.quantity = actQty;

        setDeliveryNoteData([...deliveryNoteData,
            {
                key: (deliveryNoteData.length + 1).toString(),
                product: actProduct.product,
                lotNumber: actProduct.lotNumber,
                quantity: actQty,
                slot: actProduct.slot,
            }
        ]);
        setVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOnPrint = () => {
        if(axiosInstance.current != null){

            const FileDownload = require('js-file-download');

            axiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/delivery-notes/create-deliv-note-by-stock-list',
                    method: 'POST',
                    responseType: 'blob',
                    data: deliveryNoteData
                })
                .then(response => {
                    FileDownload(response.data, 'deliv_note.pdf');
                    setDeliveryNoteData(new Array());
                    setVisible(false);
                });
        }
    };

    const action = (text: string, record: any) => {

        return  <>
            <Tooltip placement="top" title={<Trans>Delete stock</Trans>}>
                <Popconfirm title={<Trans>Are you sure to delete this stock?</Trans>}
                            onConfirm={() => handleDelete(record.id)}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}>
                    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
                </Popconfirm>
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Edit stock</Trans>}>
                <EditTwoTone
                    twoToneColor={"#40a9ff"}
                    className={"ActionButtons"} onClick={
                    () => drawer.open("Edit", {
                        id: record.id,
                        product: record.product.code,
                        slot: record.slot.id,
                        quantity: record.quantity,
                        lotNumber: record.lotNumber,
                        version : record.version
                    })}/>
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Add to Delivery Note</Trans>}>
                <RightOutlined
                    className={"ActionButtons"}
                    style={{color: "#40a9ff"}}
                    onClick={() => showModal(record)
                    }
                />
            </Tooltip>
        </>
    }

    const text = <Trans>Stock</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <StockForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const actions = {
        width: 125,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const qtyText = <Trans>Quantity</Trans>
    const delivNoteTitleText = <h2><Trans>Delivery Note</Trans></h2>


    const columns : Array<Column> = new Array();

    columns.push({key:'product',dataIndex:["product", "code"], width: 150, direction: "asc", fixed: "left", sorter:false, title:<Trans>Product</Trans>});
    columns.push({key:'lotNumber', dataIndex:["lotNumber"], width: 150, sorter:false,title:<Trans>Lot Number</Trans>});
    columns.push({key:'quantity',dataIndex:["quantity"], width: 150, sorter:false,title:<Trans>Quantity</Trans>});
    columns.push({key:'slot', dataIndex:["slot"], width: 150, sorter:false,title:<Trans>Slot</Trans>,
        render: (text, row) =>
            ({
                children: <div>
                    {row["slot"]["rack"]}:
                    {row["slot"]["row"]}:
                    {row["slot"]["col"]}
                </div>
            }),
    });

    columns.push(
        {
            key: 'action',
            sorter: false,
            width: 50,
            title: <></>,
            render: (_, record) => (
                <Space size="middle">
                    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}} onClick={() => {
                        const newData = deliveryNoteData.filter(item => item.key !== record.key);
                        setDeliveryNoteData(newData);
                    }
                    }/>
                </Space>
            ),
        }
    );

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Stocks'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={StockModel}
            tableHeader={
                <>
                <Button type={"primary"} onClick={ () => drawer.open("Add new")} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new</Trans></span></Button>
                <Popover
                    content={
                        <>
                            <Table columns={columns} dataSource={deliveryNoteData} bordered/>
                            <br />
                            <a onClick={hide}><Trans>Close</Trans></a>
                            <a style={{float: "right" }} onClick={() =>
                                handleOnPrint()
                            }><Trans>Print</Trans></a>
                        </>
                    }
                    title={delivNoteTitleText}
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                >
                    <Button type="primary" style={{float: "right" }}><SnippetsOutlined /><span><Trans>Delivery Note</Trans></span></Button>
                </Popover>
                </>
            }
            action={actions}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
        <Modal title={qtyText} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={200}>
            <InputNumber value={actQty} onChange={(value) => setActQty(value)} min={0} style={{width: "100%"}}/>
        </Modal>
        {drawer.component}
    </>
}
export default StockScreen;
