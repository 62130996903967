import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import CustomerOrderModel from "../../models/dto/customer-order-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Button, Space, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {EditTwoTone, FileImageTwoTone, PlusOutlined, PrinterOutlined, QrcodeOutlined} from "@ant-design/icons";
import {useKeycloak} from "@react-keycloak/web";
import OrderDrawer from "../../forms/order-form/order-drawer";
import Drawer from "../../components/drawer/drawer";
import FileDownload from "js-file-download";

const CustomerOrderScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();

    const [trigger, setTrigger] = useSafeState(false);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");


    const text = <Trans>Customer Order</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <OrderDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );


    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Customer Orders'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={CustomerOrderModel}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default CustomerOrderScreen;
