import useSafeState from '../../utils/safe-state';
import React, {FC, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, InputNumber} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {antdContext} from "../../utils/antdContext";
import ScanLogModel from "../../models/dto/scan-log-model";

interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const ScanLogForm  = (props: Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const config = {headers: {'Content-Type': 'application/json'}};

    const data = props.data || {};
    const [form] = Form.useForm();

    let isEditMode = false;

    useEffect(() => {

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === "Add new") {
            isEditMode = false;
        }
    }, [])

    const onReset = () => {
        form.resetFields();
    };


    const openNotification = (status: any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {
                console.log(r)
            })
        }
        if (props.onClose) {
            props.onClose();
        }
    };


    const handleEdit = () => {

        if (axiosInstance.current != null) {
            const obj = {
                id: form.getFieldValue(["id"]),
                code: form.getFieldValue(["code"]),
                version: data.version
            };
            setLoading(true);
            axiosInstance.current.put(ScanLogModel.url + "/id?id=" + form.getFieldValue(["id"]), obj,
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }
    const handleAddNew = () => {

        if (axiosInstance.current != null) {
            const obj = {
                code: form.getFieldValue(["code"]),
                version: form.getFieldValue(["version"])
            };
            setLoading(true);
            axiosInstance.current.post(ScanLogModel.url, obj, config).then(function (response: AxiosResponse<any>) {

                openNotification("success");
                setLoading(false);

            }).catch(function (error: any)
            {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    return (
        <Form id={props.mode} form={form} layout="vertical"
              onFinish={() => props.mode === "Edit" ? handleEdit() : handleAddNew()}>
            <>
                <Row gutter={24}>
                    <Col span={8} hidden={props.mode != "Edit"}>
                        <Form.Item
                            name="id"
                            label={<Trans>Id</Trans>}
                            children={<InputNumber disabled={true} />}
                            rules={[{
                                type: "number",
                                required: true,
                                message: <Trans>Please type an id</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="code"
                            label={<Trans>Code</Trans>}
                            children={<Input disabled={false}/>}
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please type a code</Trans>
                            }]}
                        />
                    </Col>

                </Row>

            </>
            {props.mode === "Edit" ?
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                props.mode === "Add new" ?
                    <>
                        <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                            <Trans>Vissza</Trans>
                        </Button>
                        <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                                onClick={() => form.submit()}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                    :
                    <></>
            }
        </Form>
    );

}
export default ScanLogForm;
