import useSafeState from '../../utils/safe-state';
import React, {FC, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, InputNumber, Select} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {antdContext} from "../../utils/antdContext";
import StockModel from "../../models/dto/stock-model";
import {SelectType} from "#/interfaces/SelectType";

interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const StockForm  = (props: Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const config = {headers: {'Content-Type': 'application/json'}};
    const [productAutoCompleteOptions,setProductAutoCompleteOptions] = useSafeState<any[]>([]);
    const [slotAutoCompleteOptions,setSlotAutoCompleteOptions] = useSafeState<any[]>([]);


    const data = props.data || {};
    const [form] = Form.useForm();

    let isEditMode = false;

    useEffect(() => {

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === "Add new") {
            isEditMode = false;
        }
        getSlotData();
        getProductData();
    }, [])

    const getSlotData = () => {

        if(!axiosInstance.current){
            return;
        }

        axiosInstance.current.get('/resource/slots')
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {
                    let sizeArray: Array<SelectType> = [];
                    response.data.forEach(value => {

                        const label = value.id;

                        sizeArray.push(
                            {
                                label: label,
                                value: label,
                            }
                        )
                    });
                    setSlotAutoCompleteOptions(sizeArray);
                }
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });
    };
    const getProductData = () => {

        if(!axiosInstance.current){
            return;
        }

        axiosInstance.current.get('/resource/products')
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {
                    let sizeArray: Array<SelectType> = [];
                    response.data.forEach(value => {

                        const label = value.code;

                        sizeArray.push(
                            {
                                label: label,
                                value: label,
                            }
                        )
                    });
                    setProductAutoCompleteOptions(sizeArray);
                }
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });
    };

    const onReset = () => {
        form.resetFields();
    };


    const openNotification = (status: any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {
                console.log(r)
            })
        }
        if (props.onClose) {
            props.onClose();
        }
    };


    const handleEdit = () => {

        if (axiosInstance.current != null) {
            const obj = {
                id: form.getFieldValue(["id"]),
                product:{
                   code:form.getFieldValue(["product"])
                },
                slot:{
                    id:form.getFieldValue(["slot"])
                },
                quantity: form.getFieldValue(["quantity"]),
                lotNumber: form.getFieldValue(["lotNumber"]),
                version: data.version
            };
            setLoading(true);
            axiosInstance.current.put("/resource/stocks/id?id=" + form.getFieldValue(["id"]), obj,
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }
    const handleAddNew = () => {

        if (axiosInstance.current != null) {
            const obj = {
                product:{
                    code:form.getFieldValue(["product"])
                },
                slot:{
                    id:form.getFieldValue(["slot"])
                },
                quantity: form.getFieldValue(["quantity"]),
                lotNumber: form.getFieldValue(["lotNumber"])
            };
            setLoading(true);
            axiosInstance.current.post(StockModel.url, obj, config).then(function (response: AxiosResponse<any>) {

                openNotification("success");
                setLoading(false);

            }).catch(function (error: any)
            {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    return (
        <Form id={props.mode} form={form} layout="vertical"
              onFinish={() => props.mode === "Edit" ? handleEdit() : handleAddNew()}>
            <>
                <Row gutter={24}>
                    <Col span={8} hidden={props.mode != "Edit"}>
                        <Form.Item
                            name="id"
                            label={<Trans>Id</Trans>}
                            children={<InputNumber disabled={true} />}
                            rules={[{
                                type: "number",
                                required: true,
                                message: <Trans>Please type an id</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="quantity"
                            label={<Trans>Quantity</Trans>}
                            children={<InputNumber disabled={false}/>}
                            rules={[{
                                type: "number",
                                required: true,
                                message: <Trans>Please type a quantity</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="lotNumber"
                            label={<Trans>Lot number</Trans>}
                            children={<Input disabled={false}/>}
                            rules={[{
                                type: "string",
                                required: false,
                                message: <Trans>Please type a lot number</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="product"
                            label={<Trans id={'Product'}/>}
                            children={
                                <Select
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder={<Trans id={'Select a product'}></Trans>}
                                    options={productAutoCompleteOptions}
                                />
                            }
                        />
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="slot"
                            label={<Trans id={'Slot'}/>}
                            children={
                                <Select
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder={<Trans id={'Select a slot'}></Trans>}
                                    options={slotAutoCompleteOptions}
                                />
                            }
                        />
                    </Col>

                </Row>

            </>
            {props.mode === "Edit" ?
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                props.mode === "Add new" ?
                    <>
                        <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                            <Trans>Vissza</Trans>
                        </Button>
                        <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                                onClick={() => form.submit()}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                    :
                    <></>
            }
        </Form>
    );

}
export default StockForm;
