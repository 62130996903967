import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const CustomerOrderModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id.voucherNumber', dataIndex:['id','voucherNumber'],direction:"asc", width: 110, sorter:true,title:<Trans>Voucher number</Trans>});
    columns.push({key:'id.productCode', dataIndex:['id','productCode'], width: 200, sorter:true,title:<Trans>Product code</Trans>});
    columns.push({key:'id.lotNumber', dataIndex:['id','lotNumber'], width: 110, sorter:true,title:<Trans>Lot number</Trans>});

    const qtyColumns: Array<Column> = new Array();
    qtyColumns.push({key:'orderedQuantity', dataIndex:['orderedQuantity'], width: 110, sorter:true,title:<Trans>Ordered</Trans>,
        render: (text, row) => ({ props: { style: { background:
                        row["orderedQuantity"] !== row["fulfilledQuantity"] ? '#ffd1d1' : ''
                } }, children: <div>{text}</div> })
    });
    qtyColumns.push({key:'fulfilledQuantity', dataIndex:['fulfilledQuantity'], width: 110, sorter:true,title:<Trans>Fulfilled</Trans>,
        render: (text, row) => ({ props: { style: { background:
                        row["orderedQuantity"] !== row["fulfilledQuantity"] ? '#ffd1d1' : ''
                } }, children: <div>{text}</div> })
    });
    columns.push({
        key: 'qty',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Quantity</Trans>,
        children: qtyColumns,
    });


    const orderAddress: Array<Column> = new Array();
    orderAddress.push({key:'country', dataIndex:['orderAddress','country'], width: 110, sorter:true, title:<Trans>Country</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'countryState', dataIndex:['orderAddress','countryState'], width: 110, sorter:true, title:<Trans>Country State</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'zip', dataIndex:['orderAddress','zip'], width: 110, sorter:true, title:<Trans>Zip</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'city', dataIndex:['orderAddress','city'], width: 110, sorter:true, title:<Trans>City</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'street', dataIndex:['orderAddress','street'], width: 110, sorter:true, title:<Trans>Street</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'number', dataIndex:['orderAddress','number'], width: 110, sorter:true, title:<Trans>Number</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'publicDomain', dataIndex:['orderAddress','publicDomain'], width: 110, sorter:true, title:<Trans>Public Domain</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'building', dataIndex:['orderAddress','building'], width: 110, sorter:true, title:<Trans>Building</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'staircase', dataIndex:['orderAddress','staircase'], width: 110, sorter:true, title:<Trans>Staircase</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'floor', dataIndex:['orderAddress','floor'], width: 110, sorter:true, title:<Trans>Floor</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'door', dataIndex:['orderAddress','door'], width: 110, sorter:true, title:<Trans>Door</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    columns.push({
        key: 'orderAddress',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Order Address</Trans>,
        children: orderAddress,
    });

    const deliveryAddress: Array<Column> = new Array();
    deliveryAddress.push({key:'country', dataIndex:['deliveryAddress','country'], width: 110, sorter:true, title:<Trans>Country</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'countryState', dataIndex:['deliveryAddress','countryState'], width: 110, sorter:true, title:<Trans>Country State</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'zip', dataIndex:['deliveryAddress','zip'], width: 110, sorter:true, title:<Trans>Zip</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'city', dataIndex:['deliveryAddress','city'], width: 110, sorter:true, title:<Trans>City</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'street', dataIndex:['deliveryAddress','street'], width: 110, sorter:true, title:<Trans>Street</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'number', dataIndex:['deliveryAddress','number'], width: 110, sorter:true, title:<Trans>Number</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'publicDomain', dataIndex:['deliveryAddress','publicDomain'], width: 110, sorter:true, title:<Trans>Public Domain</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'building', dataIndex:['deliveryAddress','building'], width: 110, sorter:true, title:<Trans>Building</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'staircase', dataIndex:['deliveryAddress','staircase'], width: 110, sorter:true, title:<Trans>Staircase</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'floor', dataIndex:['deliveryAddress','floor'], width: 110, sorter:true, title:<Trans>Floor</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    deliveryAddress.push({key:'door', dataIndex:['deliveryAddress','door'], width: 110, sorter:true, title:<Trans>Door</Trans>, render: (text, row) => ({ props: { style: { background: '#d1f0f3'} }, children: <div>{text}</div> })});
    columns.push({
        key: 'deliveryAddress',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Delivery Address</Trans>,
        children: deliveryAddress,
    });
    
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});


    return{
        columns:columns,
        url:"/resource/customer-orders"
    };

}
export default CustomerOrderModel();
