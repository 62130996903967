import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const SupplierOrderModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'orderId.orderNumber', dataIndex:['orderId','orderNumber'],direction:"asc", width: 110, sorter:true,title:<Trans>Order number</Trans>});
    columns.push({key:'orderId.productCode', dataIndex:['orderId','productCode'], width: 110, sorter:true,title:<Trans>Product code</Trans>});
    columns.push({key:'orderId.lotNumber', dataIndex:['orderId','lotNumber'], width: 110, sorter:true,title:<Trans>Lot number</Trans>});


    const qtyColumns: Array<Column> = new Array();
    qtyColumns.push({key:'orderQuantity', width: 110, sorter:true, title:<Trans>Ordered</Trans>});
    qtyColumns.push({key:'receivedQuantity', width: 110, sorter:true, title:<Trans>Received</Trans>});

    columns.push({
        key: 'qty',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Quantity</Trans>,
        children: qtyColumns,
    });

    columns.push({key:'status',tags:[
        {text:'ORDERED',color:'green'},
        {text:'RECEIVED',color:'orange'},
        {text:'RECEIVING_IN_PROGRESS',color:'purple'},
    ],
        uniqueSelectFilterData:[
            {
                label:<Trans>NONE</Trans>,
                value:null
            },{
                label:<Trans>RECEIVED</Trans>,
                value:"RECEIVED"
            },{
                label:<Trans>RECEIVING_IN_PROGRESS</Trans>,
                value:"RECEIVING_IN_PROGRESS"
            },{
                label:<Trans>ORDERED</Trans>,
                value:"ORDERED"
            }
        ],
        width: 120, sorter:true,title:<Trans>Status</Trans>
    });

    columns.push({key:'supplierContact', width: 110, sorter:true,title:<Trans>Contact</Trans>});
    columns.push({key:'supplierName', width: 110, sorter:true,title:<Trans>Name</Trans>});
    columns.push({key:'supplierPhone', width: 110, sorter:true,title:<Trans>Phone</Trans>});
    columns.push({key:'supplierEmail', width: 110, sorter:true,title:<Trans>Email</Trans>});
    const orderAddress: Array<Column> = new Array();
    orderAddress.push({key:'country', dataIndex:['supplierAddress','country'], width: 110, sorter:true, title:<Trans>Country</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'countryState', dataIndex:['supplierAddress','countryState'], width: 110, sorter:true, title:<Trans>Country State</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'zip', dataIndex:['supplierAddress','zip'], width: 110, sorter:true, title:<Trans>Zip</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'city', dataIndex:['supplierAddress','city'], width: 110, sorter:true, title:<Trans>City</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'street', dataIndex:['supplierAddress','street'], width: 110, sorter:true, title:<Trans>Street</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'number', dataIndex:['supplierAddress','number'], width: 110, sorter:true, title:<Trans>Number</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'publicDomain', dataIndex:['supplierAddress','publicDomain'], width: 110, sorter:true, title:<Trans>Public Domain</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'building', dataIndex:['supplierAddress','building'], width: 110, sorter:true, title:<Trans>Building</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'staircase', dataIndex:['supplierAddress','staircase'], width: 110, sorter:true, title:<Trans>Staircase</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'floor', dataIndex:['supplierAddress','floor'], width: 110, sorter:true, title:<Trans>Floor</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    orderAddress.push({key:'door', dataIndex:['supplierAddress','door'], width: 110, sorter:true, title:<Trans>Door</Trans>, render: (text, row) => ({ props: { style: { background: '#fffbe6'} }, children: <div>{text}</div> })});
    columns.push({
        key: 'orderAddress',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>Address</Trans>,
        children: orderAddress,
    });

    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:false, sorter:true,title:<Trans>Updated By</Trans>});



    return{
        columns:columns,
        url:"/resource/supplier-orders"
    };

}
export default SupplierOrderModel();
