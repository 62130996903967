import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const SizeModel = () : ColumnMain =>
{

    const columns : Array<Column> = new Array();
    columns.push({key:'name', width: 110, sorter:true,title:<Trans>Name</Trans>, fixed: "left", direction:"asc", render: text => {return (<b>{text}</b>);} });
    columns.push({key:'widthX', width: 200, sorter:true,title:<Trans>Width X</Trans> });
    columns.push({key:'lengthY', width: 200, sorter:true,title:<Trans>Length Y</Trans> });
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});

    return{
        columns:columns,
        url:"/resource/sizes"
    };

}

export default SizeModel();
