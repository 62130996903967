import React, {FC} from "react";
import {Trans} from "@lingui/macro";
import {Breadcrumb} from "antd";
import DtoTable from "../../components/dto-table/dto-table";
import CustomerModel from "../../models/dto/customer-model";
import useSafeState from "../../utils/safe-state";

const CustomerScreen: FC = () => {

    const [trigger, setTrigger] = useSafeState(false);


    return <>
        <Breadcrumb style={{marginBottom: 10}}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Customers'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            model={CustomerModel}
            trigger={trigger}
            pageSize={100}
            scroll={{x: 1300, y: "65vh"}}
        />
    </>
}
export default CustomerScreen;
